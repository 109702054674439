/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-unused-vars
import qs from "query-string";
import {
  getDiscountedProducts,
  getFeaturedProducts,
  getRelatedProducts,
  getTopRatedProducts,
} from "../fake-server/endpoints/products";

const API_URL = "https://api.bgl.cl/api/web";
// const API_URL = "http://127.0.0.1:8000/api/web";
const shopApi = {
  /**
   * Returns array of categories.
   * @return {Promise<Array<object>>}
   */
  getCategories: () => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/categories.json?depth=2
     *
     * where:
     * - 2 = options.depth
     */
    return fetch(`${API_URL}/categories?depth=2`).then((response) =>
      response.json()
    );
  },
  /**
   * Returns category by slug.
   *
   * @param {string} slug
   * @param {object?} options
   * @param {number?} options.depth
   *
   * @return {Promise<object>}
   */
  getCategoryBySlug: (slug, options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/categories/power-tools.json?depth=2
     *
     * where:
     * - power-tools = slug
     * - 2           = options.depth
     */
    return fetch(`${API_URL}/categories?name=${slug}`).then((response) =>
      response.json()
    );
  },
  /**
   * Returns product.
   *
   * @param {string} slug
   *
   * @return {Promise<object>}
   */
  getProductBySlug: (slug) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/products/screwdriver-a2017.json
     *
     * where:
     * - screwdriver-a2017 = slug
     */
    return fetch(`${API_URL}/item?numerointerno=${slug}`).then((response) =>
      response.json()
    );
  },
  /**
   * Returns array of related products.
   *
   * @param {string}  slug
   * @param {object?} options
   * @param {number?} options.limit
   *
   * @return {Promise<Array<object>>}
   */
  getRelatedProducts: (slug, options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/products/screwdriver-a2017/related.json&limit=3
     *
     * where:
     * - screwdriver-a2017 = slug
     * - limit             = options.limit
     */
    // return fetch(`https://example.com/api/products/${slug}/related.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return getRelatedProducts(slug, options);
  },
  /**
   * Return products list.
   *
   * @param {object?} options
   * @param {number?} options.page
   * @param {number?} options.limit
   * @param {string?} options.sort
   * @param {Object.<string, string>?} filters
   *
   * @return {Promise<object>}
   */
  getProductsList: (options = {}, filters = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/products.json?page=2&limit=12&sort=name_desc&filter_category=screwdriwers&filter_price=500-1000
     *
     * where:
     * - page            = options.page
     * - limit           = options.limit
     * - sort            = options.sort
     * - filter_category = filters.category
     * - filter_price    = filters.price
     */
    // const params = { ...options };
    //
    // Object.keys(filters).forEach((slug) => {
    //     params[`filter_${slug}`] = filters[slug];
    // });
    //

    let request = `${API_URL}/items`;

    let params = { ...options, ...filters };
    if (Object.keys(params).length !== 0) {
      request += `?${qs.stringify(params)}`;
    }

    return fetch(request).then((response) => {
      return response.json();
    });

    // This is for demonstration purposes only. Remove it and use the code above.
  },
  /**
   * Returns array of featured products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getFeaturedProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/featured-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    // return fetch(`https://example.com/api/featured-products.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return getFeaturedProducts(options);
  },
  /**
   * Returns array of latest products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getLatestProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/latest-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    return fetch(`${API_URL}/last_release`).then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    //return getLatestProducts(options);
  },
  /**
   * Returns an array of top rated products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getTopRatedProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/top-rated-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    // return fetch(`https://example.com/api/top-rated-products.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return getTopRatedProducts(options);
  },
  /**
   * Returns an array of discounted products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getDiscountedProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/discounted-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    // return fetch(`https://example.com/api/discounted-products.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return getDiscountedProducts(options);
  },
  /**
   * Returns an array of most popular products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getPopularProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/popular-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    return fetch(`${API_URL}/featured`).then((response) => {
      return response.json();
    });

    // This is for demonstration purposes only. Remove it and use the code above.
    //return getPopularProducts(options);
  },
  /**
   * Returns search suggestions.
   *
   * @param {string}  query
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getSuggestions: (query, options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
     *
     * where:
     * - query    = query
     * - limit    = options.limit
     * - category = options.category
     */
    let queryString = `${API_URL}/search/suggestions?${qs.stringify({
      ...options,
      query,
    })}`;
    return fetch(queryString).then((response) => response.json());
  },
  /**
   * Sends contact form email.
   *
   * @param {string}  name
   * @param {string} last_name
   * @param {string} email
   * @param {string} subject
   * @param {string} body
   *
   * @return {Promise<Array<object>>}
   */
  sendContactForm: (
    email,
    name,
    phone,
    subject,
    message,
    numerointerno,
    link
  ) => {
    return fetch(`${API_URL}/contact`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        name: name,
        phone: phone,
        numerointerno: numerointerno,
        subject: subject,
        message: message,
        link: link,
      }),
    }).then((response) => response.json());
  },
};

export default shopApi;

// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import departmentsAria from '../../services/departmentsArea';
import languages from '../../i18n';
import StroykaSlick from '../shared/StroykaSlick';


const slickSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class BlockSlideShow extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    slides = [
        {
            title: 'HELICOPTERO BELL 407/2004',
            text: 'Venta directa, se aceptan ofertas',
            link: 'https://helicoptero.bgl.cl',
            link_text: 'Ver más',
            external: true,
            image_classic: {
                ltr: 'images/slides/helicopter-slide.png',
            },
            image_full: {
                ltr: 'images/slides/helicopter-slide.png',
            },
            image_mobile: {
                ltr: 'images/slides/h-slide-mobile.png',
            },
        },
        
        {
            title: '¡Vende tu auto con nosotros',
            text: 'Consigna tu auto con BGL para una venta más segura',
            link: 'sitio/contacto',
            link_text: 'Contactar',
            external: false,
            image_classic: {
                ltr: 'images/slides/slide-5-full-ltr.png',
            },
            image_full: {
                ltr: 'images/slides/slide-5-full-ltr.png',
            },
            image_mobile: {
                ltr: 'images/slides/slide-5-mobile.png',
            }
        },
        {
            title: 'Venta de propiedad en Calama por BGL',
            text: 'Propiedad “HOSTAL” lista para ser remodelada',
            link: 'https://propiedad.bgl.cl',
            link_text: 'Link a Propiedad',
            external: true,
            image_classic: {
                ltr: 'images/slides/calama-slide.png',
            },
            image_full: {
                ltr: 'images/slides/calama-slide.png',
            },
            image_mobile: {
                ltr: 'images/slides/calama-slide.png',
            },
        },


        {
            title: 'Más de 24 años de experiencia',
            text: '¿Está pensando en cambiar su flota?<br>Venda con BGL<br>Agilidad confianza y servicio',
            link: '/sitio/quienes-somos',
            link_text: 'Quienes somos',
            external: false,
            image_classic: {
                ltr: 'images/slides/slide-2-full-ltr.jpg',
            },
            image_full: {
                ltr: 'images/slides/slide-2-full-ltr.jpg',
            },
            image_mobile: {
                ltr: 'images/slides/slide-2-mobile.jpg',
            },
        },
        {
            title: 'BGL su asesor especializado',
            text: 'En comercialización de activos, mediante procesos rápidos, eficientes y transparentes',
            link: 'bienes/CAMIONES',
            link_text: 'CAMIONES',
            external: false,
            image_classic: {
                ltr: 'images/slides/slide-1-full-ltr.jpg',
            },
            image_full: {
                ltr: 'images/slides/slide-1-full-ltr.jpg',
            },
            image_mobile: {
                ltr: 'images/slides/slide-1-mobile.jpeg',
            },
        },
        {
            title: '¿Necesita comprar equipos?',
            text: 'En BGL tenemos una gran variedad de maquinarias y camiones',
            link: 'bienes/maquinaria-mov_d_-tierra_agricola',
            link_text: 'MAQUINARIA MOV. TIERRA-AGRICOLA',
            external: false,
            image_classic: {
                ltr: 'images/slides/slide-3-full-ltr.jpg',
            },
            image_full: {
                ltr: 'images/slides/slide-3-full-ltr.jpg',
            },
            image_mobile: {
                ltr: 'images/slides/slide-3-mobile.jpg',
            },
        },
    ];

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { locale, withDepartments } = this.props;
        const { direction } = languages[locale];

        const blockClasses = classNames(
            'block-slideshow block',
            {
                'block-slideshow--layout--full': !withDepartments,
                'block-slideshow--layout--with-departments': withDepartments,
            },
        );

        const layoutClasses = classNames(
            'col-12',
            {
                'col-lg-12': !withDepartments,
                'col-lg-9': withDepartments,
            },
        );

        const slides = this.slides.map((slide, index) => {
            const image = (withDepartments ? slide.image_classic : slide.image_full)[direction];

            return (
                <div key={index} className="block-slideshow__slide">
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: '100% auto',
                        }}
                    />
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `url(${slide.image_mobile[direction]})`,
                           
                        }}
                    />
                    <div className="block-slideshow__slide-content">
                        <div
                            className="block-slideshow__slide-title"
                            dangerouslySetInnerHTML={{ __html: slide.title }}
                        />
                        <div
                            className="block-slideshow__slide-text"
                            dangerouslySetInnerHTML={{ __html: slide.text }}
                        />
                        {slide.external ? (
                            <div className="block-slideshow__slide-button">
                                <a href={slide.link} target='_blank' rel="noopener noreferrer" className="btn btn-primary btn-lg">{slide.link_text}</a>
                            </div>
                        ):(
                        <div className="block-slideshow__slide-button">
                            <Link to={slide.link} className="btn btn-primary btn-lg">{slide.link_text}</Link>
                        </div>
                         )}
                    </div>
                </div>
            );
        });

        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        {withDepartments && (
                            <div className="col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                        )}

                        <div className={layoutClasses}>
                            <div className="block-slideshow__body">
                                <StroykaSlick {...slickSettings}>
                                    {slides}
                                </StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);

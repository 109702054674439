// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// application
import Footer from "./footer";
import Header from "./header";
import MobileHeader from "./mobile/MobileHeader";
import MobileMenu from "./mobile/MobileMenu";
import Quickview from "./shared/Quickview";

// pages
import PageCompare from "./shop/ShopPageCompare";
import ShopPageCategory from "./shop/ShopPageCategory";
import ShopPageProduct from "./shop/ShopPageProduct";
import SitePageAboutUs from "./site/SitePageAboutUs";
import SitePageComponents from "./site/SitePageComponents";
import SitePageContactUsAlt from "./site/SitePageContactUsAlt";
import SitePageFaq from "./site/SitePageFaq";
import SitePageNotFound from "./site/SitePageNotFound";
import SitePageTerms from "./site/SitePageTerms";

// data stubs
import theme from "../data/theme";
import WhatsAppButton from "./shared/WhatsAppButton";

function Layout(props) {
  const { match, headerLayout, homeComponent } = props;
  return (
    <React.Fragment>
      <Helmet>
        <title>{theme.fullName}</title>
        <meta name="description" content={theme.description} />
      </Helmet>

      <ToastContainer autoClose={5000} hideProgressBar />

      <Quickview />

      <MobileMenu />

      <div className="site">
        <header className="site__header d-lg-none">
          <MobileHeader />
        </header>

        <header className="site__header d-lg-block d-none">
          <Header layout={headerLayout} />
        </header>

        <div className="site__body">
          <WhatsAppButton />
          <Switch>
            {/*
                        // Home
                        */}
            <Route exact path={`${match.path}`} component={homeComponent} />
            {/*
                        // Site
                        */}
            <Redirect exact from="/sitio" to="/sitio/quienes-somos" />
            <Route
              exact
              path="/sitio/quienes-somos"
              component={SitePageAboutUs}
            />
            <Route
              exact
              path="/sitio/components"
              component={SitePageComponents}
            />
            <Route
              exact
              path="/sitio/contacto"
              component={SitePageContactUsAlt}
            />
            <Route
              path="/sitio/contacto/:numerointerno"
              render={(props) => (
                <SitePageContactUsAlt
                  {...props}
                  numerointerno={props.match.params.numerointerno}
                />
              )}
            />
            <Route
              exact
              path="/sitio/no-encontrado"
              component={SitePageNotFound}
            />
            <Route exact path="/sitio/faq" component={SitePageFaq} />
            <Route exact path="/sitio/terms" component={SitePageTerms} />

            {/*
                        // Shop
                        */}
            <Route
              exact
              path="/bienes"
              render={(props) => (
                <ShopPageCategory
                  {...props}
                  columns={3}
                  viewMode="grid"
                  sidebarPosition="start"
                />
              )}
            />
            <Route
              exact
              path="/bienes/:categorySlug"
              render={(props) => (
                <ShopPageCategory
                  {...props}
                  columns={3}
                  viewMode="grid"
                  sidebarPosition="start"
                  categorySlug={props.match.params.categorySlug}
                />
              )}
            />
            <Route
              exact
              path="/bienes/:categorySlug/:subCategorySlug"
              render={(props) => (
                <ShopPageCategory
                  {...props}
                  columns={3}
                  viewMode="grid"
                  sidebarPosition="start"
                  categorySlug={props.match.params.categorySlug}
                  subCategorySlug={props.match.params.subCategorySlug}
                />
              )}
            />
            <Route
              exact
              path="/:categorySlug/:subCategorySlug"
              render={(props) => (
                <ShopPageCategory
                  {...props}
                  columns={3}
                  viewMode="grid"
                  sidebarPosition="start"
                  categorySlug={props.match.params.categorySlug}
                  subCategorySlug={props.match.params.subCategorySlug}
                />
              )}
            />

            <Route
              exact
              path="/bienes/:categorySlug/:subCategorySlug/:productName/:productSlug"
              render={(props) => (
                <ShopPageProduct
                  {...props}
                  layout="standard"
                  categorySlug={props.match.params.categorySlug}
                  subCategorySlug={props.match.params.subCategorySlug}
                  productName={props.match.params.productName}
                  productSlug={props.match.params.productSlug}
                />
              )}
            />

            <Route
              exact
              path="/:productSlug"
              render={(props) => (
                <ShopPageProduct
                  {...props}
                  layout="standard"
                  productSlug={props.match.params.productSlug}
                />
              )}
            />

            {/*
                        // Page Not Found
                        */}
            <Route component={SitePageNotFound} />
          </Switch>
        </div>

        <footer className="site__footer">
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}

Layout.propTypes = {
  /**
   * header layout (default: 'classic')
   * one of ['classic', 'compact']
   */
  headerLayout: PropTypes.oneOf(["default", "compact"]),
  /**
   * home component
   */
  homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
  headerLayout: "default",
};

export default Layout;
